export enum facilityAddOns {
  SUGGESTED_CONTENT = 'SUGGESTED_CONTENT',
  FAVOURITES = 'FAVOURITES',
  FAMILY = 'FAMILY',
  DEMENTIA = 'DEMENTIA',
  TEMPORARY_LOCK = 'TEMPORARY_LOCK',
  WHO_IS_LISTENING = 'WHO_IS_LISTENING',
  TV = 'TV',
}

export const addonsViewValues = {
  SUGGESTED_CONTENT: 'Suggested Content',
  FAVOURITES: 'Favourites',
  FAMILY: 'Life Story',
  DEMENTIA: 'Dementia',
  TEMPORARY_LOCK: 'Temporary Lock',
  WHO_IS_LISTENING: 'Who is Listening',
  TV: 'Remote TV Control',
}