
export interface ResidentFamily {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  residentId: string;
}

export enum FamilyInviteStatus {
  DETAILS_REQUIRED='DETAILS_REQUIRED',
  DETAILS_SAVED='DETAILS_SAVED',
  DETAILS_SENT='DETAILS_SENT',
  DETAILS_UPDATED='DETAILS_UPDATED',
}